import React from "react"

const QuandaryValuesCareer = () => {
  return (
    <>
      <div className="text-center">
        <div className="qvalues ">
          <div className="peaks row justify-content-center peaks-wrapper mb-0">
            <div className="rounded col-lg-2 col-sm-4 mb-2">
              <h1 className="peaks-blue font-weight-bold text-center">P</h1>
              <p
                className="h5 mt-0 text-white font-weight-bold text-break"
                style={{ fontSize: "1.2rem" }}
              >
                Professionalism
              </p>
              <p className>
                Elevate professional and personal standards daily.
              </p>
            </div>
            <div className="rounded col-lg-2 col-sm-4 mb-2">
              <h1 className="peaks-blue font-weight-bold text-center">E</h1>
              <p
                className="h5 mt-0 text-white font-weight-bold text-break"
                style={{ fontSize: "1.2rem" }}
              >
                {" "}
                Enthusiasm
              </p>
              <p>
                Search for new solutions that benefit our clients and our team.
              </p>
            </div>
            <div className="rounded col-lg-2 col-sm-4 mb-2">
              <h1 className="peaks-blue font-weight-bold text-center">A</h1>
              <p
                className="h5 mt-0 text-white font-weight-bold text-break"
                style={{ fontSize: "1.2rem" }}
              >
                Accountability
              </p>
              <p>
                Self-reflect and take responsibility for your actions and words.
              </p>
            </div>
            <div className="rounded col-lg-2 col-sm-4 mb-2">
              <h1 className="peaks-blue font-weight-bold text-center">K</h1>
              <p
                className="h5 mt-0 text-white font-weight-bold text-break"
                style={{ fontSize: "1.2rem" }}
              >
                Knowledge
              </p>
              <p>
                Always seek out new opportunities to improve your knowledge
                base.
              </p>
            </div>
            <div className="rounded col-lg-2 col-sm-4 mb-2">
              <h1 className="peaks-blue font-weight-bold text-center">S</h1>
              <p
                className="h5 mt-0 text-white font-weight-bold text-break"
                style={{ fontSize: "1.2rem" }}
              >
                {" "}
                Support
              </p>
              <p>
                Mentor and train teammates knowing that when the team wins,
                everyone wins.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuandaryValuesCareer
